import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DOMPurify from "dompurify";
import useAlloyVoice from "./useAlloyVoice";
import voice from "../assets/images/voice1.gif";

const VoiceInterview = () => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { convertToSpeech } = useAlloyVoice();

  const [jobDescription, setJobDescription] = useState("");
  const [userResponse, setUserResponse] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [jobTitle, setJobTitle] = useState("");
  const [answerCount, setAnswerCount] = useState(0);
  const [result, setResult] = useState(null);
  const [feedBacks, setFeedBacks] = useState("");
  const [applicantId, setApplicantId] = useState(
    location.state?.applicantId || ""
  );
  const [resume, setResume] = useState(location.state?.resume || "");
  const vacancyId = params.id;
  const answerCountRef = useRef(0);
  const recognitionRef = useRef(null);
  const [isListening, setIsListening] = useState(false);
  const [isGeneratingQuestion, setIsGeneratingQuestion] = useState(true);

  useEffect(() => {
    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;

    if (SpeechRecognition) {
      const recognition = new SpeechRecognition();
      recognition.lang = "en-US";
      recognition.interimResults = false;
      recognition.maxAlternatives = 1;
      recognitionRef.current = recognition;

      recognition.onresult = (event) => {
        const transcript = event.results[0][0].transcript;
        setUserResponse(transcript);
        handleAskQuestion(transcript);
      };

      recognition.onerror = (event) => {
        toast.error("Speech recognition error: " + event?.error);
        setIsListening(false);
      };

      recognition.onend = () => setIsListening(false);
    }
  }, []);

  const startListening = () => {
    if (recognitionRef?.current) {
      setIsListening(true);
      recognitionRef?.current.start();
    }
  };

  const completeInterview = async () => {
    try {
      const { data: evaluationData } = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/ai/evaluate`,
        { jobDescription, chatHistory }
      );

      const evaluation = evaluationData?.evaluation || "No evaluation";
      const feedback = evaluationData?.summaryFeedback || "No feedback";
      setResult(evaluation);
      setFeedBacks(feedback);

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/ai/createInterviewResult`,
        { applicantId, vacancyId, score: evaluation, feedback }
      );

      if (data?.success) {
        toast.success("Your Application has been submitted");
        navigate("/dashboard/candidate/applicant");
      }
    } catch (error) {
      toast.error("Failed to complete the interview.");
    }
  };

  const handleAskQuestion = async (userResponse = "") => {
    try {
      setIsGeneratingQuestion(true);
      const responses = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/ai/generateFollowUpQuestion`,
        { jobDescription, userResponse, chatHistory }
      );
      const botMessage = responses.data.question.content;
      await convertToSpeech(botMessage, () => {
        startListening();
      });
      setIsGeneratingQuestion(false);
      setChatHistory((prevHistory) => [
        ...prevHistory,
        { sender: "User", message: userResponse },
        { sender: "Interviewer", message: botMessage },
      ]);

      setAnswerCount((prevCount) => {
        const newCount = prevCount + 1;
        answerCountRef.current = newCount;
        return newCount;
      });

      setUserResponse("");

      if (answerCountRef.current === 9) {
        completeInterview();
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const generateInitialQuestion = async (
    jobTitle,
    jobType,
    experiencelevel,
    experienceUnit,
    skill,
    jobDescription,
    resume
  ) => {
    try {
      setIsGeneratingQuestion(true);
      const responses = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/ai/getInitialQuestion`,
        {
          jobTitle,
          jobType,
          experiencelevel,
          experienceUnit,
          skill,
          jobDescription,
          resume,
        }
      );

      const botMessage = responses.data.question.content;

      await convertToSpeech(botMessage, () => {
        startListening();
      });
      setIsGeneratingQuestion(false);
      setChatHistory([{ sender: "Interviewer", message: botMessage }]);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const getVacancy = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/vacancy/getSingleVacancy/${params.id}`
      );

      if (data?.success) {
        const jobDetails = data.singleVacancy;
        setJobTitle(jobDetails.jobTitle);
        generateInitialQuestion(
          jobDetails.jobTitle,
          jobDetails.jobType,
          jobDetails.experiencelevel.value || 0,
          jobDetails.experiencelevel.unit,
          jobDetails.skills,
          DOMPurify.sanitize(jobDetails.jobDescription),
          resume
        );
      }
    } catch (error) {
      toast.error("Failed to load vacancy details");
    }
  };

  useEffect(() => {
    getVacancy();
  }, []);

  return (
    <>
      <div className="flex items-center justify-center h-screen bg-[#141414] relative">
        {/* Center GIF */}
        <div className="absolute left-1/2 transform -translate-x-1/2">
          <img
            src={voice}
            alt="Voice Animation"
            className="w-60 h-60 md:w-80 md:h-80 object-contain"
          />
        </div>

        {/* Right Middle Text */}
        <div className="absolute right-4 md:right-10 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-75 p-4 md:p-6 shadow-lg rounded-lg border border-[#0db8e6]">
          <div className="text-[#38b9d1] mt-2 text-sm md:text-xl opacity-90 max-w-[500px] max-h-[300px] overflow-y-auto">
            {isGeneratingQuestion ? (
              <div className="flex items-center">
                <span>Creating the question</span>
                <span className="dot-flash"></span>
              </div>
            ) : (
              chatHistory.length > 0 && (
                <div>{chatHistory[chatHistory.length - 1].message}</div>
              )
            )}
          </div>

          {/* Buttons */}
          <div className="mt-4 flex space-x-4">
            {isListening && (
              <button className="text-xl px-4 py-2 bg-[#38b9d1] text-white font-semibold rounded-lg hover:bg-[#2a95a9] transition">
                Listening...
              </button>
            )}
          </div>
        </div>
      </div>
      {/* <div className="flex flex-col h-screen bg-gray-100">
        <header className="bg-blue-500 text-white p-4">
          <h1 className="text-3xl">Interview - {jobTitle}</h1>
        </header>
        <main className="flex-1 overflow-y-auto p-4">
          {chatHistory.map((entry, index) => (
            <div
              key={index}
              className={`mb-2 p-2 rounded-lg ${
                entry.sender === "User"
                  ? "bg-blue-100 self-end"
                  : "bg-gray-300 self-start"
              }`}
            >
              {console.log("Entry", entry)}
              <strong>{entry.sender}:</strong> {entry.message}
            </div>
          ))}
        </main>
        <footer className="p-4 bg-white border-t border-gray-300">
          <button
            onClick={startListening}
            className="p-2 bg-green-500 text-white rounded-lg"
          >
            {isListening ? "Listening..." : "Speak Your Answer"}
          </button>
        </footer>
      </div> */}
    </>
  );
};

export default VoiceInterview;
