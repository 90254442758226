import axios from "axios";
import React, { useEffect, useState } from "react";
import voice from "../assets/images/voice1.gif";
const AlloyVoice = () => {
  const [audioUrl, setAudioUrl] = useState(null);
  const [isReady, setIsReady] = useState(false); // Track if user interacted

  useEffect(() => {
    const fetchSpeech = async () => {
      try {
        const response = await axios.post(
          "http://localhost:5000/api/v1/ai/create-speech",
          {
            text: "Experiment with different voices to find one that matches your desired tone and audience.",
          },
          {
            headers: { "Content-Type": "application/json" },
            responseType: "blob",
          }
        );

        const audioBlob = new Blob([response.data], { type: "audio/mpeg" });
        const url = URL.createObjectURL(audioBlob);
        setAudioUrl(url);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchSpeech();
  }, []);

  const handlePlayAudio = () => {
    if (audioUrl) {
      const newAudio = new Audio(audioUrl);
      newAudio.play();
    }
  };

  return (
    <div className="flex items-center justify-center h-screen bg-[#141414] relative">
      {/* Center GIF */}
      <div className="absolute left-1/2 transform -translate-x-1/2">
        <img
          src={voice}
          alt="Voice Animation"
          className="w-60 h-60 md:w-80 md:h-80 object-contain"
        />
      </div>

      {/* Right Middle Text */}
      <div className="absolute right-4 md:right-10 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-75 p-4 md:p-6 shadow-lg rounded-lg border border-[#38b9d1]">
        <p className="text-[#38b9d1] mt-2 text-sm md:text-xl opacity-90">
          Please answer the questions
        </p>

        {/* Buttons */}
        <div className="mt-4 flex space-x-4">
          <button className="text-xl px-4 py-2 bg-[#38b9d1] text-white font-semibold rounded-lg hover:bg-[#2a95a9] transition">
            Start Speaking
          </button>
        </div>
      </div>
    </div>

    // <div className="bg-white p-4" onClick={() => setIsReady(true)}>
    //   <h1 className="text-xl font-bold mb-4">Inside Voice</h1>
    //   {isReady && audioUrl && handlePlayAudio()}{" "}
    //   {/* Play audio only after interaction */}
    //   {audioUrl && (
    //     <audio className="mt-4" controls>
    //       <source src={audioUrl} type="audio/mpeg" />
    //       Your browser does not support the audio element.
    //     </audio>
    //   )}
    // </div>
  );
};

export default AlloyVoice;
