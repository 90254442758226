import React, { useState } from "react";
import recruiter from "../../../assets/hero/recruiter.png";
import applicant from "../../../assets/hero/applicant.png";
import straightLine from "../../../assets/hero/straightLine.png";
import dottedLineBelow from "../../../assets/hero/dottedLineBelow.png";
import dottedLineAbove from "../../../assets/hero/dottedLineAbove.png";

import { useUserRole } from "../../../context/userRoleContext";
import DemoBookingModal from "../../demoBookingModal/DemoBookingModal";
import { useNavigate } from "react-router-dom";

const HeroSection = () => {
  const { userRole } = useUserRole();
  const heroSectionImage = userRole === "recruiter" ? recruiter : applicant;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <main className="flex flex-col items-center justify-center text-3xl">
      <div
        className="relative w-full min-h-screen bg-cover bg-center"
        style={{
          backgroundImage: `url(${heroSectionImage})`,
        }}
      >
        <div
          className=" absolute top-1/2 left-[2rem] transform -translate-y-1/2"
          data-aos="flip-left"
        >
          <div className="text-white">
            {/* Straight Line */}
            <div
              className="w-1/3 h-2 bg-no-repeat bg-contain mb-4  ml-[12rem] md:ml-[18rem]"
              style={{
                backgroundImage: `url(${straightLine})`,
              }}
            />
            {/* Dotted Line Above */}
            <div
              className="w-2/3 h-2 bg-no-repeat bg-contain mb-4"
              style={{
                backgroundImage: `url(${dottedLineAbove})`,
              }}
            />
            {/* Conditional Heading */}
            {userRole === "recruiter" ? (
              <>
                <h2 className="tracking-widest px-4 font-semibold relative md:text-5xl">
                  <span className="md:text-6xl font-cinzel">S</span>UMMON{" "}
                  <span className="md:text-6xl font-cinzel">Y</span>OUR <br />
                </h2>
                <h2 className="px-4 font-semibold relative md:text-5xl">
                  <span className="md:text-6xl font-cinzel">P</span>ERFECT{" "}
                  <span className="md:text-6xl font-cinzel">T</span>EAM{" "}
                </h2>
              </>
            ) : (
              <>
                <h2 className="tracking-widest px-4 font-semibold relative md:text-5xl">
                  <span className="md:text-6xl font-cinzel">F</span>IND{" "}
                  <span className="md:text-6xl font-cinzel">Y</span>OUR{" "}
                </h2>
                <h2 className="px-4 font-semibold relative md:text-5xl">
                  <span className="md:text-6xl font-cinzel">P</span>ERFECT{" "}
                  <span className="md:text-6xl font-cinzel">J</span>OB{" "}
                </h2>
              </>
            )}
            {/* Dotted Line Below */}
            <div
              className=" h-2 bg-no-repeat bg-contain my-4"
              style={{
                backgroundImage: `url(${dottedLineBelow})`,
              }}
            />
            {/* Button */}
            {userRole === "recruiter" ? (
              <button
                className="px-12 py-2 border border-white rounded-2xl hover:bg-white hover:text-black"
                onClick={openModal}
              >
                <span className="text-4xl font-cinzel">B</span>OOK{" "}
                <span className="text-4xl font-cinzel">Y</span>OUR{" "}
                <span className="text-4xl font-cinzel">D</span>EMO{" "}
                <span className="text-4xl font-cinzel">N</span>OW
              </button>
            ) : (
              <button
                className="px-12 py-2 border border-white rounded-2xl hover:bg-white hover:text-black"
                onClick={() => navigate("/comingSoon")}
              >
                <span className="text-4xl font-cinzel">S</span>TART{" "}
                <span className="text-4xl font-cinzel">N</span>OW{" "}
              </button>
            )}
          </div>
        </div>
      </div>
      <DemoBookingModal isOpen={isModalOpen} onRequestClose={closeModal} />
    </main>
  );
};

export default HeroSection;
