import axios from "axios";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { customStyles } from "../../styles/modalStyles";

const CandidateProfile = () => {
  const [candidate, setCandidate] = useState();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    resume: "",
  });

  const [file, setFile] = useState(null);
  const [resumeFile, setResumeFile] = useState(null);
  // Resume feedback state
  const [resumeFeedback, setResumeFeedback] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getCandidateData();
  }, []);

  const getCandidateData = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/user/displayUser`
      );
      if (response.data.success) {
        setCandidate(response.data.user);
        setFormData({
          firstName: response.data.user.firstName,
          lastName: response.data.user.lastName,
          email: response.data.user.email,
          resume: response.data.user.resume,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };
  const handleResumeChange = (e) => {
    setResumeFile(e.target.files[0]);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const submissionData = new FormData();
    submissionData.append("firstName", formData.firstName);
    submissionData.append("lastName", formData.lastName);
    if (resumeFile) {
      submissionData.append("resume", resumeFile);
    }
    if (file) {
      submissionData.append("profilePicture", file);
    }
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/v1/user/updateUser`,
        submissionData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      if (response.data.success) {
        setCandidate(response.data.updatedUser);
        closeModal();
      }
    } catch (error) {
      console.log(error);
    }
  };
  // Function to check resume
  const handleCheckResume = async () => {
    if (!candidate?.resume) {
      toast.error("No resume uploaded to check.");
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/user/checkResume`,
        { resumeUrl: candidate.resume }
      );
      if (response.data.success) {
        setResumeFeedback(response.data.feedback);
      }
    } catch (error) {
      console.error("Error checking resume:", error);
    } finally {
      setLoading(false);
    }
  };
  const getFileName = (filePath) => {
    const fileName = filePath.split("/").pop(); // Get the last part of the URL
    return fileName.substring(fileName.indexOf("_") + 1); // Remove prefix
  };

  return (
    <div className="max-w-3xl mx-auto space-y-4">
      <h2 className="text-2xl font-semibold mb-4 border-b-2 border-gray-200 pb-2">
        Candidate Profile
      </h2>

      {candidate ? (
        <div className="flex flex-col space-y-2">
          <div className="flex items-center">
            <strong className="w-1/3 text-gray-700">First Name:</strong>
            <span className="ml-2 text-gray-900">{candidate.firstName}</span>
          </div>
          <div className="flex items-center">
            <strong className="w-1/3 text-gray-700">Last Name:</strong>
            <span className="ml-2 text-gray-900">{candidate.lastName}</span>
          </div>
          <div className="flex items-center">
            <strong className="w-1/3 text-gray-700">Email:</strong>
            <span className="ml-2 text-gray-900">{candidate.email}</span>
          </div>
          <div className="flex items-center">
            <strong className="w-1/3 text-gray-700">Resume:</strong>
            <span className="ml-2 text-gray-900">
              {candidate.resume ? (
                <>
                  <div className="flex ">
                    <p className="mr-4">{getFileName(candidate.resume)}</p>
                    <a
                      href={candidate.resume}
                      download
                      className="text-blue-500 underline"
                    >
                      Download Resume
                    </a>
                  </div>
                </>
              ) : (
                "No resume uploaded"
              )}
            </span>
          </div>
          <div className="flex items-center">
            <strong className="w-1/3 text-gray-700">Profile Picture:</strong>
            <span className="ml-2 text-gray-900">
              {candidate.profilePicture && (
                <img src={candidate.profilePicture} alt="Profile" />
              )}
            </span>
          </div>
          <button
            onClick={handleCheckResume}
            className="bg-green-500 hover:bg-green-700 text-white py-2 px-4 rounded"
          >
            Check Resume
          </button>
          {loading && <p className="text-blue-500">Checking resume...</p>}
          {resumeFeedback && (
            <Modal
              isOpen={!!resumeFeedback}
              onRequestClose={() => setResumeFeedback(null)}
              contentLabel="Resume Feedback"
            >
              <h2 className="text-xl font-semibold mb-4">Resume Feedback</h2>
              <p className="whitespace-pre-wrap">{resumeFeedback}</p>
              <button
                onClick={() => setResumeFeedback(null)}
                className="mt-4 bg-gray-500 text-white py-2 px-4 rounded"
              >
                Close
              </button>
            </Modal>
          )}
          <button
            onClick={openModal}
            className="mt-4 bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded"
          >
            Edit Profile
          </button>
        </div>
      ) : (
        <div>
          <p>Loading...</p>
        </div>
      )}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Edit Profile"
        style={customStyles}
      >
        <h2 className="text-xl mb-4">Edit Profile</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block mb-1" htmlFor="firstName">
              First Name:
            </label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <div>
            <label className="block mb-1" htmlFor="lastName">
              Last Name:
            </label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <div>
            <label className="block mb-1" htmlFor="email">
              Email:
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded"
              disabled
            />
          </div>
          <div>
            <label className="block mb-1" htmlFor="resume">
              Resume:
            </label>
            <input
              type="file"
              accept=".pdf"
              id="resume"
              name="resume"
              onChange={handleResumeChange}
              className="w-full px-3 py-2 border rounded"
            />
            {formData?.resume && (
              <div className="flex">
                <p className="mr-4">{getFileName(candidate.resume)}</p>
                <a
                  href={candidate.resume}
                  download
                  className="text-blue-500 underline"
                >
                  View Current Resume
                </a>
              </div>
            )}
          </div>

          <div>
            <label className="block mb-1" htmlFor="file">
              Profile Picture:
            </label>
            <input
              type="file"
              accept="image/*"
              id="file"
              onChange={handleFileChange}
              className="w-full px-3 py-2 border rounded"
            />
            {candidate?.profilePicture && (
              <div>
                <p>Current Profile Picture:</p>
                <img src={candidate.profilePicture} alt="Profile" width="100" />
              </div>
            )}
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={closeModal}
              className="mr-2 px-4 py-2 border rounded bg-gray-300"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded"
            >
              Save
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default CandidateProfile;
