import axios from "axios";

const useAlloyVoice = () => {
  const convertToSpeech = async (text, onSpeechEnd) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/ai/create-speech`,
        { text },
        {
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "blob",
        }
      );

      const audioBlob = response.data;
      const url = URL.createObjectURL(audioBlob);
      const audio = new Audio(url);
      audio.play();
      //When speech finishes, trigger to callback to start listening
      audio.onended = () => {
        if (onSpeechEnd) {
          onSpeechEnd();
        }
      };
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return { convertToSpeech };
};

export default useAlloyVoice;
