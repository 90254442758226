import React, { useEffect } from "react";

import Layout from "../components/Layout/Layout";
import Pricing from "../components/Services/PricingSection";
import ScrollSection from "../components/Services/ScrollSection";
import ServiceHeroSection from "../components/Services/ServiceHeroSection";
import ServiceWaitingFor from "../components/Services/ServiceWaitingFor";
import { useLocation } from "react-router-dom";

const Services = () => {
  return (
    <>
      <Layout title="WizardHire AI - Services">
        <ServiceHeroSection />
        <ScrollSection />
        <div id="pricing">
          <Pricing />
        </div>
        <ServiceWaitingFor />
      </Layout>
    </>
  );
};
export default Services;
